import { createStore } from 'vuex'
import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';
import { getCookie } from "@/utils/storage";
export default createStore({
  state: {
    lang: '', // 当前语言
    allLang: [], // 可选语言列表
    accessToken: getCookie('atk') || '', // token
    refreshToken: getCookie('rfk') || '', // token
    navCollapse: getCookie('navCollapse') ==='true' || false,
    rememberPass: getCookie('remP') ==='true' || false,
    userNickname: getCookie('un') || '',
  },
  getters,
  mutations,
  actions,
  modules: {
  }
})
