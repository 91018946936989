import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-671ac862"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "nav-list"
};
import { computed, onMounted, reactive, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  __name: 'NavList',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const navList = ref([]);
    const curNav = computed(() => {
      return route.path;
    });
    const navClick = pane => {
      router.push(pane.paneName);
    };
    onMounted(() => {
      navList.value = router.options.routes.filter(el => !el.meta.hidden);
    });
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      return _openBlock(), _createElementBlock("nav", _hoisted_1, [_createVNode(_component_el_tabs, {
        type: "border-card",
        "model-value": curNav.value,
        onTabClick: navClick
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.value, nav => {
          return _openBlock(), _createBlock(_component_el_tab_pane, {
            key: nav.name,
            name: nav.path,
            label: nav.meta?.title
          }, null, 8, ["name", "label"]);
        }), 128))]),
        _: 1
      }, 8, ["model-value"])]);
    };
  }
};