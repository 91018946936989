import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const iconName = computed(() => {
      return props.iconClass ? `#icon-${props.iconClass}` : '#icon';
    });
    const svgClass = computed(() => {
      return props.className ? `svg-icon ${props.className}` : 'svg-icon';
    });
    return {
      iconName,
      svgClass
    };
  }
});