import { createRouter, createWebHistory } from 'vue-router';
import { $i18n } from '@/utils';
const routes = [
  {
    path: '/',
    redirect: '/qrCode',
    meta: {
      hidden: true
    },
  },
  {
    path: '/qrCode',
    name: 'qrCode',
    component: () => import(/* webpackChunkName: "login" */ '@/views/qrCode/qrCodeCreate.vue'),
    meta: {
      title: $i18n('qrCode.qrcodeProduct'),
      keepAlive: true,
    },
  },
  {
    path: '/qrCodeReader',
    name: 'qrCodeReader',
    component: () => import(/* webpackChunkName: "login" */ '@/views/qrCode/qrCodeReader.vue'),
    meta: {
      title: $i18n('qrCode.qrcodeReader'),
      keepAlive: true,
    },
  },
  {
    path: '/timeStamp',
    name: 'timeStamp',
    component: () => import(/* webpackChunkName: "login" */ '@/views/time/timeStamp.vue'),
    meta: {
      title: $i18n('time.timeStamp'),
      keepAlive: true,
    },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/',
    meta: {
      hidden: true
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
