import { alertTime } from '@/config';
import i18n from '@/i18n';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
/**
 * 弹出框显示
 * @param {String} content 提示内容
 * @param {String} title 提示标题
 * @param {String} type 提示类型，默认为 error
 * @param {Boolean} needCancel 是否需要取消按钮
 *  */
export function $message(content, title, type, needCancel, cancelButtonText, confirmButtonText) {
  const options = {
    title: title || $i18n('message.tips'),
    message: content,
    type: type || 'error',
    customClass: 'custom-message-box',
    showCancelButton: needCancel || false,
    cancelButtonText: cancelButtonText || $i18n('message.cancel'),
    confirmButtonText: confirmButtonText || $i18n('message.confirm'),
    closeOnClickModal: needCancel || false,
    closeOnPressEscape: needCancel || false,
    showClose: needCancel || false
  };
  return ElMessageBox(options);
}
export function $alert(msg, type, showClose, ops = {}) {
  const options = {
    showClose: showClose || true,
    message: msg,
    customClass: 'custom-alert',
    type: type || 'error',
    duration: alertTime || 3000,
    ...ops
  };
  ElMessage(options);
}
export function $i18n(key, options, nullValue) {
  const result = i18n.global.t(key, options);
  return result === key ? nullValue : result;
}
export function getRouteTitle(meta) {
  return meta && meta.title && $i18n(meta.title) || '';
}
// 检验字符串是不是md5
export function isMd5(inputString) {
  return /[a-fA-F0-9]{32}/.test(inputString);
}
// 格式化时间
export function timeFormat(time, str) {
  if (!time) {
    return '';
  }
  return moment(time).format(str || 'YYYY-MM-DD HH:mm:ss');
}
// 获取uuid
export function getUuid() {
  return uuidv4();
}
// 复制文字
export async function copyText(text) {
  let copySuccess = true;
  await navigator.clipboard.writeText(text).catch(err => {
    copySuccess = false;
    $alert($i18n('public.copyFail'));
  });
  copySuccess && $alert($i18n('public.copySuccess'), 'success');
}