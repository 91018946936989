import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-56a1ab0c"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(_ctx.svgClass),
    "aria-hidden": "true"
  }, [_createElementVNode("use", {
    "xlink:href": _ctx.iconName
  }, null, 8, _hoisted_1)], 2);
}