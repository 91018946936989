import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import '@/utils/permissions';
import './icons';
import SvgIcon from '@/components/SvgIcon';
import '@/styles/global.scss';
import '@/styles/root.scss';
import 'default-passive-events';
import directive from '@/directives/index';
// import '@/mock/index.js';
const vueApp = createApp(App);
vueApp.use(store);
vueApp.use(router);
vueApp.use(i18n);
vueApp.use(directive);
vueApp.component('svg-icon', SvgIcon);
vueApp.mount('#app');
