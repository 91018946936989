const modulesFiles = require.context('./modules', true, /\.js$/);
const reg = /.\/(.*)\.js$/;
const modules = modulesFiles.keys().reduce((modules, modulePath)=>{
  const moduleName = modulePath.match(reg)[1];
    modules[moduleName] = modulesFiles(modulePath);
    return modules;
},{})
export  default{
    install(app) {
        for(let key in modules) {
            app.directive(key, modules);
        }
    }
}