import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-270340f3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "footer"
};
export function render(_ctx, _cache) {
  const _component_el_link = _resolveComponent("el-link");
  return _openBlock(), _createElementBlock("footer", _hoisted_1, [_createVNode(_component_el_link, {
    target: "_blank",
    type: "info",
    underline: false,
    href: "https://beian.miit.gov.cn/#/Integrated/index"
  }, {
    default: _withCtx(() => [_createTextVNode("沪ICP备2023033114号-1 ")]),
    _: 1
  })]);
}