/* eslint-disable no-console */
import { register } from 'register-service-worker'
import { $message, $i18n } from '@/utils';
import { ElNotification } from 'element-plus';
var hasNoFresh = true;
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered (registration) {
      // 通过测试新的服务工作线程来定期检查应用更新
      setInterval(() => {
        registration.update();
      }, 1000); // 这里为了测试 每秒检查一次
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      ElNotification({
        type: 'info',
        title: $i18n('message.tips'),
        message: $i18n('message.newVersionDownload'),
      });
      console.log('New content is downloading.', registration)
    },
    updated () {
      console.log('New content is available; please refresh.')
      if(hasNoFresh) {
        hasNoFresh = false;
        $message($i18n('message.versionFreshTip'), null, 'warning', true, $i18n('message.btnLater'), $i18n('message.btnFresh')).then(res => {
          if(res === 'confirm') {
            window.location.reload();
          }
        })
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
